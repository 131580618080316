.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

a {
    /* color: rgba(255, 255, 255, 0.50); */
    font-weight: 100;
    padding-bottom: 6px;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 6px;
    text-decoration: none;
    text-transform: uppercase;
}

a:hover {
    /* color: rgba(255, 255, 255, 0.85); */
}

a.active {
    /* color: white; */
    font-weight: bold;
}

a.active:visited {
    /* color: white; */
    font-weight: bold;
    text-decoration: none;
}

a:visited {
    /* color: white; */
    text-decoration: none;
}

.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: rgba(255, 255, 255, 0.50);
    border-style: dashed;
    background-color: clear;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
}

img {
    /* height: 100%;
    width: 100%; */
    object-fit: fill;
}

.MuiCard-root {
    height: 100%;
}

.MuiCardMedia-img {
    object-fit: cover;
}

.background {
    background-image: url("https://storage.googleapis.com/diffusitron-prod.appspot.com/Images/1A82D584-EED0-4DD7-89C0-586600C15854/image_11-02-2023_02%3A38%3A20.jpg");
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: cover;
    filter: blur(12px) brightness(45%);
}

.content {
    position: relative;
    background-color: transparent;
}

.grid {
    background-color: rgba(255, 255, 255, 0.15);
    width: 100%;
}

.foo {
    background-color: red;
}

.text {
    background-color: green;
}

.hero {
    max-width: 400px;
    max-height: 650px;
}

.react-grid-layout {
    position: relative;
    transition: height 200ms ease;
}

.react-grid-item {
    transition: all 200ms ease;
    transition-property: left, top;
}

.react-grid-item img {
    pointer-events: none;
    user-select: none;
}

.react-grid-item.cssTransforms {
    transition-property: transform;
}

.react-grid-item.resizing {
    z-index: 1;
    will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
    transition: none;
    z-index: 3;
    will-change: transform;
}

.react-grid-item.dropping {
    visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
    background: red;
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.react-grid-item>.react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
}

.react-grid-item>.react-resizable-handle::after {
    content: "";
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 5px;
    height: 5px;
    border-right: 2px solid rgba(0, 0, 0, 0.4);
    border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

.react-resizable-hide>.react-resizable-handle {
    display: none;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-sw {
    bottom: 0;
    left: 0;
    cursor: sw-resize;
    transform: rotate(90deg);
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-se {
    bottom: 0;
    right: 0;
    cursor: se-resize;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-nw {
    top: 0;
    left: 0;
    cursor: nw-resize;
    transform: rotate(180deg);
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-ne {
    top: 0;
    right: 0;
    cursor: ne-resize;
    transform: rotate(270deg);
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-w,
.react-grid-item>.react-resizable-handle.react-resizable-handle-e {
    top: 50%;
    margin-top: -10px;
    cursor: ew-resize;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-w {
    left: 0;
    transform: rotate(135deg);
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-e {
    right: 0;
    transform: rotate(315deg);
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-n,
.react-grid-item>.react-resizable-handle.react-resizable-handle-s {
    left: 50%;
    margin-left: -10px;
    cursor: ns-resize;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-n {
    top: 0;
    transform: rotate(225deg);
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-s {
    bottom: 0;
    transform: rotate(45deg);
}

